.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 16px;
  margin: auto;
}

.icon {
  display: flex;
  max-width: 500px;
  max-height: 500px;
  object-fit: contain;
  fill: var(--info);

  .small & {
    max-width: 150px;
    max-height: 150px;
  }
}

.title {
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  color: var(--info);
  text-transform: uppercase;

  &Primary {
    color: var(--primary);
  }

  &Secondary {
    color: var(--error);
  }

  .small & {
    font-size: 16px;
    font-weight: 500;
    text-transform: none;
  }
}

.children {
  text-align: center;
}
