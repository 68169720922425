@import 'src/assets/styles/mixins';

.root {
  padding: 32px calc(min(10vw, 140px));
}

.root {
  padding-top: 32px;
  padding-bottom: 32px;
  min-height: calc(100vh - 68px);
  display: flex;
  flex-flow: column;
  gap: 24px;
  position: relative;

  @include mq(lg-down) {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}


.arrowBack {
  position: absolute;
  top: 32px;
  left: 32px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
}

.baseInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__wrapper {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__avatar {
    width: 88px;
    height: 88px;
    border-radius: 16px;
    object-fit: cover;
  }

  &__label {
    margin-bottom: 4px;
    font-size: 16px;
    line-height: 20px;
    color: var(--tone-600);
  }

  &__username {
    color: var(--tone-900);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
  }
}

.stats {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 24px;
}

.gameStats {
  &__total {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    color: var(--tone-900, rgba(17, 22, 39, 0.96));
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;

    strong {
      font-weight: inherit;
    }
  }

  &__arrowIndicator {
    margin-left: 8px;

    &__down {
      path {
        stroke: var(--accent-red-500);
      }
    }

    &__up {
      transform: rotate(180deg);

      path {
        stroke: var(--accent-green-500);
      }
    }
  }

  &__percentChange {
    margin-bottom: 18px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    display: flex;
    align-items: center;
    column-gap: 4px;

    strong {
      font-weight: inherit;
    }

    &__down {
      color: var(--accent-red-500);

      svg path {
        stroke: var(--accent-red-500);
      }
    }

    &__up {
      color: var(--accent-green-500);

      svg path {
        stroke: var(--accent-green-500);
      }
    }
  }
}

.winningStats {
  &__totals {
    display: flex;
    align-items: center;
    column-gap: 40px;
    margin-bottom: 24px;
  }

  &__currency {
    display: flex;
    align-items: center;
    column-gap: 12px;
  }

  &__currencyIcon {
    width: 40px;
    height: 40px;
    background-color: var(--bg-02);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &__currencyInfo {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    row-gap: 4px;
  }

  &__currencyLabel {
    color: var(--tone-600);
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }

  &__currencyAmount {
    color: var(--tone-900);
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
  }

  &__link {
    display: flex;
    align-items: center;
    column-gap: 4px;
    color: var(--comp-500);
    transition: color 0.2s ease-in-out;

    &:hover {
      color: var(--comp-600);
    }
  }

  &__linkIcon {
    width: 16px;
    height: 16px;
    transform: rotate(180deg);
  }
}

.gameStats,
.winningStats {
  &__header {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: var(--tone-900);
  }
}

.accountSections {
  display: grid;
  grid-template-columns: 210px 1fr;

  &__nav {
    padding-right: 24px;
    border-right: 1px solid var(--tone-400);
    display: flex;
    flex-flow: column;
    gap: 20px;
  }

  &__navItem {
    display: flex;
    align-items: center;
    column-gap: 12px;
    cursor: pointer;
    color: var(--tone-700);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    border-radius: 20px;
    padding: 12px;

    svg {
      path {
        stroke: var(--tone-700);
      }
    }

    &:hover {
      color: var(--brand-500);

      svg {
        path {
          stroke: var(--brand-500);
        }
      }
    }

    &.activeProfileLink {
      color: var(--brand-500);
      background: var(--brand-100);

      svg {
        path {
          stroke: var(--brand-500);
        }
      }
    }
  }

  &__content {
    padding-left: 32px;
  }
}