.root {
    background-image: url('./images/hero.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right top;
    min-height: 680px;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    //z-index: 3;

    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 32px;
    }

    .container {
        margin-top: -100px;
        z-index: 10;
    }

    .title {
        color: #FFF;
        text-align: center;
        text-shadow: 0px 4px 48px rgba(138, 142, 204, 0.24);
        font-size: 80px;
        font-weight: 700;
        line-height: 108%;
        max-width: 800px;
        margin-bottom: -8px;

        /* 112px */
        @media (max-width: 768px) {
            text-shadow: 0px 2.15px 25.798px rgba(138, 142, 204, 0.24);
            font-size: 40px;
        }
    }

    .subtitle {
        display: flex;
        flex-direction: column;
        gap: 16px;

        p {
            color: #FFF;
            text-align: center;
            text-shadow: 0px 4px 24px rgba(172, 177, 255, 0.16);
            font-size: 20px;
            font-weight: 600;
            line-height: 120%;

            @media (max-width: 768px) {
                text-shadow: 0px 2.298px 13.788px rgba(172, 177, 255, 0.16);
                font-size: 14px;
            }
        }

        span {
            color: #EFF0F5;
            text-align: center;
            font-size: 18px;
            font-weight: 400;
            line-height: 26px;
            max-width: 456px;
            display: inline-block;

            @media (max-width: 768px) {
                font-size: 12px;
                line-height: 15px;
                max-width: 260px;
            }
        }
    }

    .buttons {
        display: flex;
        gap: 12px;

        @media (max-width: 768px) {
            gap: 6px;
        }

        .button {
            width: 200px !important;
            height: 70px !important;
            text-align: center;
            font-size: 20px;
            font-weight: 500;
            line-height: 70px;

            &:last-child {
                border-radius: 28px;
                background: #FFF;
                color: var(--brand-500);
                backdrop-filter: blur(2px);

                &:hover {
                    background: rgba(255, 255, 255, 0.35);
                }
            }

            @media (max-width: 768px) {
                min-width: unset !important;
                width: 112px !important;
                height: 44px !important;
                padding: 14px 12px !important;
                font-size: 13px;
                line-height: 18px;
            }
        }
    }

    .info {
        display: flex;
        gap: 10px;

        @media (max-width: 768px) {
            gap: 8px;

            img {
                width: 68px;
                height: 28px;
            }
        }

        p {
            color: #EFF0F5;
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;

            @media (max-width: 768px) {
                color: transparent;
                font-size: 0;

                &::before {
                    content: 'Thousands are already playing';
                    position: relative;
                    bottom: -7px;
                    color: #EFF0F5;
                    font-size: 11px;
                    font-weight: 400;
                    line-height: 12px;
                }
            }
        }
    }

    .BottomGold,
    .LeftMan,
    .RightMan {
        position: absolute;
    }

    .BottomGold {
        bottom: -30px;
        left: 50%;
        transform: translateX(-50%);

        @media (max-width: 768px) {
            width: 100%;
            bottom: -70px;
        }

        @media (max-width: 600px) {
            bottom: -85px;
        }

        @media (max-width: 425px) {
            bottom: -90px;
        }
    }

    .LeftMan {
        bottom: 0;
        left: 0;
        transform: translateX(-35%);

        @media (max-width: 768px) {
            height: 320px;
            transform: translateX(-47.5%);
        }
    }

    .RightMan {
        bottom: 0;
        right: 0;
        transform: translateX(35%);

        @media (max-width: 768px) {
            height: 320px;
            transform: translateX(45%);
        }
    }
}