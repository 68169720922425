.root {
    padding: 90px 0;
    @media (max-width: 991px) {
        padding: 50px 0;
    }


    .title {
        color: #111627;
        margin-bottom: 58px;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Kanit;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 118%;
        /* 42.48px */
    }

    .wrapper {
        display: flex;
        gap: 24px;
        @media (max-width: 1350px) {
            flex-direction: column;
        }
    
    }

    .left {
        display: flex;
        gap: 24px;
        width: 50%;
        flex-wrap: wrap;
        @media (max-width: 1350px) {
           width: 100%;
        }
    }

    .item {
        width: calc(50% - 12px);
        background-color: #fff;
        height: 223px;
        border-radius: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        @media (max-width: 991px) {
            width: 100%;
         }

        &__title {
            color: #111627;
            text-align: center;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Kanit;
            font-size: 36px;
            font-style: normal;
            font-weight: 600;
            line-height: 118%;
            /* 42.48px */
            margin-bottom: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 12px;
        }

        &__bg {
            position: absolute;
            top: 0;
            right: 0;
            border-radius: 0 24px 0 0;
        }

        &__smart_contract {
            color: #111627;
            text-align: center;
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Kanit;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 118%;
            /* 28.32px */
            margin-top: 16px;
            margin-bottom: 8px;
        }
        .copy__message{
            margin-top: -30px;
            margin-bottom: 10px;
            opacity: 0;
            transition: opacity 0.2s;
            &_active{
                opacity: 1;
            }
        }
    }
    
    .right {
        width: 50%;
        border-radius: 24px;
        img{
            border-radius: 24px;
            @media (max-width: 1350px) {
                margin:  0 auto;
             }
             @media (max-width: 991px) {
                width: 100%;
             }
        }
        @media (max-width: 1350px) {
            width: 100%;
         }
    }
}