.games {
  &__row {
    color: var(--tone-900);
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 8px;

    &.date {
      font-weight: 300;
    }

    .roomIcon {
      margin-right: 4px;
      min-width: 40px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }

  &__noGames {
    min-height: 300px;

    svg {
      fill: none !important;
    }

    p {
      margin-bottom: 24px;
      color: var(--tone-700);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  &__filters {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__filter {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background 0.2s ease-in-out;

    &:hover {
      background: var(--tone-200);
    }
  }

  &__popover {
    margin-top: 8px;
    padding: 8px 16px;
    border-radius: 16px;
    background: var(--bg-01);
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
    width: 172px;

    &__subtitle {
      margin-bottom: 8px;
      color: var(--tone-600);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }

    &__options {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    &__option {
      height: 40px;
      padding: 8px;
      display: flex;
      align-items: center;
      column-gap: 8px;
      color: var(--tone-900);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      border-radius: 12px;
      cursor: pointer;

      &.selected, &:hover {
        background: var(--bg-02, #F6F8FA);
      }
    }
  }
}