.root {
  display: block;
}

.text {
}

.rectangular {
}

.circular {
}

.pulse {
}

.wave {
}

.withChildren {
}

.fitContent {
}

.heightAuto {
}
