.root {
  font-family: inherit;
}

.errorColor {
  background-color: var(--error);

  .arrow {
    color: var(--error);
  }
}

.arrow {
}
