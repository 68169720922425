.root {
    min-height: 100vh;
    margin-top: -75px;
    padding: 150px 0 200px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @media (max-width: 768px) {
        min-height: unset;
        padding: 150px 0;
    }

    .container {
        z-index: 20;
    }

    .title {
        color: #FFF;
        max-width: 675px;
        margin: 0 auto;
        text-align: center;
        font-family: Kanit;
        font-size: 64px;
        font-style: normal;
        font-weight: 700;
        line-height: 118%;

        /* 75.52px */
        @media (max-width: 768px) {
            font-size: 40px;
        }
    }

    iframe {
        position: relative;
        z-index: 10;
        width: 960px !important;
        height: 540px !important;
        display: block;
        margin: 64px auto 0;
        border-radius: 24px;

        @media (max-width: 1200px) {
            width: 700px !important;
            height: 390px !important;
        }

        @media (max-width: 991px) {
            width: 670px !important;
            height: 360px !important;
        }

        @media (max-width: 768px) {
            width: 100% !important;
            height: 350px !important;
            margin-top: 32px;
        }

        @media (max-width: 670px) {
            height: 300px !important;
        }

        @media (max-width: 450px) {
            height: 250px !important;
        }
    }

    &>img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

:global(canvas) {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    z-index: 10;
    position: relative;

    @media (max-width: 991px) {
        display: none;
    }
}