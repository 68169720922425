.root {
  width: 264px;
  background-color: var(--white);
  padding: 24px;
  box-shadow: 8px 0 48px 0 rgba(0, 0, 0, 0.04);
  z-index: 2;
  display: flex;
  flex-direction: column;

  .logo {
    margin: 0 auto;
    width: calc(100% - 26px);
  }

  .navList {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 100px;

    .navItem {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 9px;
      border-radius: 20px;

      svg {
        &:nth-child(1) {
          display: block;
        }

        &:nth-child(2) {
          display: none;
        }
      }

      span {
        font-family: var(--font);
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #13141a;
        opacity: 0.7;
      }

      &.active {
        background-color: #1AB1CF;

        svg {
          &:nth-child(1) {
            display: none;
          }

          &:nth-child(2) {
            display: block;
          }
        }

        span {
          color: #fff;
          opacity: 1;
        }
      }
    }
  }

  .news {
    border-radius: 20px;
    background: var(--bg-02);
    padding: 32px 46px 16px;
    margin: auto 0 0;
    position: relative;

    &>div {
      display: flex;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);

      svg {
        border: 4px solid #fff;
        border-radius: 50%;
        width: 48px;
        height: 48px;
        cursor: pointer;
      }
    }

    p {
      color: var(--tone-700);
      text-align: center;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
    }
  }
}