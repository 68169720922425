.root {
  padding: 32px calc(min(10vw, 140px));
  position: relative;
}

.container {
  border-radius: 24px;
  background: var(--bg-01);
  width: 100%;
  padding: 56px;

  h1, h2 {
    color: var(--tone-900);
    font-style: normal;
  }

  h1 {
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  }

  h2 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  }

  h3 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  p {
    margin-bottom: 8px;
    color: var(--tone-700);
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;

    &.textBeforeList {
      margin-top: 16px;
    }
  }

  strong {

  }

  ul {
    margin-bottom: 24px;
    color: var(--tone-700);
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;

    li {
      margin-bottom: 14px;
      list-style: disc;
      margin-left: 16px;
    }
  }
}

.arrowBack {
  position: absolute;
  top: 32px;
  left: 32px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
}
