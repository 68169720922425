@import 'src/assets/styles/mixins';

.paymentModal {
  &__dialog {
    max-width: calc(min(480px, 90vw)) !important;
  }

  &__wrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    min-height: 527px;

    & > button {
      width: 100% !important;
    }
  }

  &__title {
    margin-bottom: 32px;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }

  &__rateSeparator {
    margin: 28px 0;
    width: 100%;
    height: 1px;
    background: var(--tone-400);
    position: relative;
  }

  &__rate {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0 10px;
    width: max-content;
    display: flex;
    align-items: center;
    column-gap: 8px;
    background: var(--bg-01);
  }

  &__inputError {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 20px 1fr;
    column-gap: 8px;
    color: var(--accent-red-500);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  &__estimate {
    margin-bottom: 24px;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 12px;
  }

  &__estimateLine {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__estimateLabel {
    color: var(--tone-700);
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;

    strong {
      color: var(--tone-900);
      font-weight: 400;
    }
  }

  &__estimateValue {
    color: var(--tone-800);
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    strong {
      color: var(--tone-900);
      font-weight: 400;
    }
  }
}

.upperBlock,
.lowerBlock {
  padding: 24px;
  border-radius: 24px;
  width: 100%;

  &__header {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__input {
    flex: 1;
    max-width: 200px;
    margin-right: 12px;
    border: none;
    color: var(--tone-900);
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    background: transparent;

    &::placeholder {
      font-family: inherit;
      color: var(--tone-500);
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &__title {
    color: var(--tone-600);
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    text-transform: uppercase;
  }

  &__fixAmount {
    color: var(--accent-blue-500);
    background: transparent;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      opacity: 0.8;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &__coinName {
    border-radius: 16px;
    background: var(--comp-400);
    padding: 4px 8px;
    color: var(--absolute-100);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    text-transform: initial;
  }

  &__bitgems,
  &__CHT {
    display: flex;
    align-items: center;
    column-gap: 4px;
    color: var(--tone-700);
    text-align: right;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
  }
}

.upperBlock {
  border-radius: 24px;
  border: 1px solid var(--bg-01);
  background: var(--bg-02);
}

.lowerBlock {
  border: 1px solid var(--tone-400);
  background: var(--bg-01);
}

.externalWallet {
  margin: 16px 0 8px;
  width: 100%;

  &__icon {
    margin-right: 8px;
  }
}

.walletAddressError {
  padding-left: 8px;
  width: 100%;
}

.successDialog {
  &__dialog {
    max-width: calc(min(480px, 90vw)) !important;
  }

  &__wrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }

  &__successIcon {
    margin: 0 auto 24px;
    width: 94px;
    height: 94px;
    border-radius: 50%;
  }

  &__title {
    margin-bottom: 24px;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }

  &__subtitle {
    margin-bottom: 24px;
    max-width: 352px;
    color: var(--tone-700);
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
  }
}

.purchaseDetails {
  width: 100%;
  padding: 20px;
  border-radius: 24px;
  border: 1px solid var(--tone-400);
  display: flex;
  flex-flow: column;
  row-gap: 16px;

  &__header {
    display: flex;
    align-items: center;
    column-gap: 8px;
    color: var(--tone-900);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__label {
    color: var(--tone-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
  }

  &__value {
    color: var(--tone-800);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    align-items: center;
    column-gap: 4px;

    svg {
      width: 20px;
      min-width: 20px;
      height: 20px;
    }
  }

  &__status {
    width: 8px;
    height: 8px;
    border-radius: 50%;

    &.pending {
      background-color: var(--accent-yellow);
    }

    &.success {
      background-color: var(--accent-green);
    }
  }

  &__separator {
    border: none;
    border-top: 1px dashed var(--tone-300);
    height: 1px;
    width: 100%;
    display: block;
    background: transparent;
    margin: 0;
  }
}

.moreInfoDialog {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  &__paper {
    max-width: calc(min(480px, 90vw)) !important;
  }

  &__text {
    padding-bottom: 16px;
    max-height: 60vh;
    overflow-y: auto;
    padding-right: 8px;
  }

  h2 {
    margin-bottom: 32px;
    color: var(--tone-900);
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    max-width: 340px;
  }

  h3 {
    margin: 32px 0;
    color: var(--tone-900);
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  }

  h4 {
    margin: 16px 0;
    color: var(--tone-900);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  p {
    color: var(--tone-700);
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
  }

  ul {
    margin-bottom: 24px;
    color: var(--tone-700);
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    list-style: none;

    li {
      margin-bottom: 14px;
      margin-left: 20px;

      &::before {
        content: '\2022';
        color: var(--brand-500);
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
  }

  &__buttonWrapper {
    width: 100%;
    padding-top: 24px;
    border-top: 1px solid var(--tone-400, rgba(118, 130, 173, 0.23));

    button {
      width: 100% !important;
    }
  }
}
