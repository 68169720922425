@import 'src/assets/styles/mixins';

.root {
  padding-top: 45px;
  padding-bottom: 45px;

  @include mq(lg-down) {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.banner {
  display: flex;
  position: relative;

  .paralax {
    position: relative;

    img,
    svg {
      &:nth-child(2) {
        right: 20%;
        top: auto !important;
        left: auto !important;
        bottom: 0;
        width: 39%;
      }

      :global(#text-cloud) {
        //display: none;
        animation-duration: 5s;
        animation-name: wiggle;
        animation-play-state: running;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
      }
    }
  }

  .info {
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 32px 72px;
    gap: 12px;

    &>div {
      display: flex;
      flex-direction: column;
      width: calc(33.333% - 8px);
      gap: 8px;
      padding: 24px;
      border-radius: 24px;
      background: var(--absolute-100, #FFF);
      box-shadow: 0 16px 48px 0 rgba(0, 0, 0, 0.04);

      h3 {
        color: var(--tone-900);
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
      }

      p {
        color: var(--tone-700);
        font-size: 14px;
        font-weight: 300;
        line-height: 18px;

        b {
          color: var(--tone-900);
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
        }

        strong {
          color: var(--brand-500);
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
        }
      }
    }
  }
}

@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }

  80% {
    transform: rotate(0deg);
  }

  85% {
    transform: rotate(-2deg) translate(-5px, 3px);
  }

  95% {
    transform: rotate(-2deg) translate(-5px, 3px);
  }

  100% {
    transform: rotate(0deg);
  }
}

.tableTabs {
  display: flex;
  align-items: center;
  gap: 20px;

  &__tab {
    color: var(--tone-500);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    cursor: pointer;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: var(--comp-500);
    }

    &.active {
      color: var(--comp-500);
    }
  }
}