@import 'reset';
@import 'vars';
@import 'helpers';
@import 'mixins';
//@import '../fonts/Inter/stylesheet';
//@import '../fonts/Montserrat/stylesheet';
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&display=swap');

body {
  font-family: var(--font);
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  color: var(--tone-700);
  background-color: var(--bg-02);
}

a {
  color: var(--info);
}

.root {
  display: flex;
  flex-flow: column;
  height: 100vh;
}

.page-wrap {
  flex: 1;
  background-color: var(--light);
  overflow: auto;
}

.pretty-scroll {
  overflow: auto;
  @include scrollbar(5px, var(--primary), transparent, 5px, 2px);
  //@include scrollbar(5px, var(--primary), transparent, 2px, 0px);

  &:not(.scroll) {
    &::-webkit-scrollbar-track {
      background-color: transparent !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #9addea !important;
    }
  }
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
  max-width: var(--container);
  width: 100%;
}

.big-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 32px;
  padding-right: 32px;
  max-width: var(--big-container);
  width: 100%;
}

.card-wrap {
  padding: 24px 32px;
  background-color: var(--white);
  border-radius: 24px;
  box-shadow: 0 16px 48px 0 rgba(0, 0, 0, 0.04);
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: 600;
  color: var(--tone-900);
}

h1,
.h1 {
  font-size: 25px;
  line-height: 32px;
}

h2,
.h2,
h3,
.h3 {
  font-size: 24px;
  line-height: 24px;
}

h3,
.h3 {
  font-size: 18px;
}

.backlink {
  font-size: 10px;
  line-height: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--info);

  &:hover {
    opacity: 0.7;
  }

  svg {
    margin-right: 8px;
  }
}

// Table
.table {
  tr {
    border-bottom: 1px solid var(--light);
  }

  th {
    vertical-align: middle;
    padding: 8px 16px;
    height: 50px;
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    color: var(--info);
  }

  td {
    vertical-align: middle;
    padding: 8px 16px;
    height: 60px;
  }
}

.table-inner {
  display: flex;
  gap: 16px;

  &--right {
    justify-content: right;
  }

  &--center {
    justify-content: center;
  }
}

// Helper classes
.flex {
  display: flex;
  flex-wrap: wrap;

  &--column {
    flex-direction: column;
  }
}

.no-wrap {
  flex-wrap: nowrap;
}

.gap-25 {
  gap: 25px;
}

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.full-width {
  width: 100%;
}

.t-center {
  text-align: center;
}

.good {
  color: var(--success);
}

.alert {
  color: var(--error);
}

.warning {
  color: var(--warning);
}

.bold {
  font-weight: 700;
}

.break-word {
  overflow-wrap: break-word;
}

.lowercase {
  text-transform: lowercase;
}

.truncate-two-lines {
  @include truncateLineClamp();
}

.good-hover {
  transition: opacity var(--transition-duration);

  @media (hover) {

    &:hover,
    &:focus-visible {
      opacity: 0.7;
    }
  }
}

.card-hover {
  cursor: pointer;
  transition: margin-top var(--transition-duration),
    margin-bottom var(--transition-duration);

  @media (hover) {

    &:hover,
    &:focus-visible {
      margin-top: -3px;
      margin-bottom: 3px;
    }
  }
}

.no-select {
  user-select: none;
}

// Animations
.pulse {
  @media (hover) {

    &:hover,
    &:focus-visible {
      animation: pulse 2s infinite;
    }
  }
}

@keyframes pulse {
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  0% {
    opacity: 1;
  }
}

// React Toastify
.Toastify__toast {
  font-family: inherit;
  white-space: break-spaces;

  &--error {
    background-color: var(--error);
  }

  &--success {
    background-color: var(--success);
  }
}

hr {
  width: 100%;
  height: 1px;
  background: rgba(118, 130, 173, 0.12);
  margin: 16px 0;
}

.disable {
  user-select: none;
  pointer-events: none;
}

.loading-price {
  display: inline-flex !important;
}

.rdrCalendarWrapper {
  border-radius: 24px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);

  * {
    font-family: var(--font);
  }

  .rdrDay {
    height: 44px;
  }

  .rdrStartEdge,
  .rdrEndEdge {
    color: var(--brand-500) !important;
  }

  .rdrInRange {
    color: var(--tone-200) !important;
  }

  .rdrDay:not(.rdrDayPassive) .rdrInRange~.rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrSelected~.rdrDayNumber span {
    color: var(--tone-800);
  }

  .rdrDayNumber {
    color: var(--tone-800);
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
  }

  .rdrWeekDay {
    color: var(--tone-700);
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .rdrMonthAndYearPickers {
    color: var(--tone-900);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}

.featurebase-widget-button-iframe {
  bottom: 100px !important;
}

.featurebase-widget-iframe.featurebase-widget-iframe-show {
  bottom: 160px;
}