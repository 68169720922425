.root {
  display: flex;
  min-height: 100vh;
  padding: 120px 32px;
  background: {
    image: url('../../assets/images/auth-bg.png'),
      linear-gradient(
        180deg,
        rgba(159, 150, 253, 1) 0%,
        rgba(195, 188, 251, 1) 25%,
        rgba(228, 223, 249, 1) 50%,
        rgba(248, 244, 248, 1) 75%,
        rgba(255, 252, 248, 1) 100%
      );
    position: bottom 0 left 50%;
    size: cover;
    repeat: no-repeat;
  }
}

.logo {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 200px;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
}
