.comingSoon {
  display: flex;
  flex: 1;

  &Inner {
    padding: 16px;
    flex: 1;
    // 100vh - header height
    height: calc(100vh - 68px);

    :global(.inner-wrap) {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h2 {
        margin-top: 24px;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: #868b96;
      }
    }
  }
}
