.root {
    padding: 90px 0;
    background-color: #fff;
    @media (max-width: 991px) {
        padding: 50px 0;
    }

    .subtitle {
        color: #315D8F;
        text-align: center;
        font-family: Kanit;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        /* 28.8px */
        @media (max-width: 991px) {
            font-size: 20px;
        }
    }

    .title {
        color: #111627;
        text-align: center;
        font-family: Kanit;
        font-size: 64px;
        font-style: normal;
        font-weight: 700;
        line-height: 118%;
        /* 75.52px */
        @media (max-width: 991px) {
            font-size: 40px;
        }
    }

    .banner {
        width: 100%;
        margin-top: 64px;
    }

    .flex {
        display: flex;
        gap: 32px;
        margin-top: 90px;
        @media (max-width: 991px) {
            margin-top: 50px;
            flex-wrap: wrap;
        }

        &__box {
            border-radius: 24px;
            background: #F6F8FA;
            padding: 56px 56px 100px;
            width: 50%;
            background-image: url('./images/box1.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: right bottom;
            @media (max-width: 991px) {
                width: 100%;
            }
            @media (max-width: 768px) {
                padding: 25px 25px 100px;
            }

            &:last-child {
                background-image: url('./images/box2.png');
            }

            &_title {
                color: #111627;
                margin-bottom: 16px;
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: Kanit;
                font-size: 36px;
                font-style: normal;
                font-weight: 600;
                line-height: 118%;
                /* 42.48px */
            }

            & > div {
                max-width: 320px;
                color: rgba(17, 22, 39, 0.72);
                font-family: Kanit;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
                /* 144.444% */
            }
        }
    }

    .banner{
        @media (max-width: 768px) {
            display: none;
        }
    }
    .bannerMob{
        display: none;
        @media (max-width: 768px) {
            display: block;
            width: 100%;
            margin-top: 30px;
        }
    }
}