.root{
    margin-top: -120px;
    position: relative;
    z-index: -1;
    @media (max-width: 768px) {
       margin-top: -65px;
    }
    .desktop{
        @media (max-width: 768px) {
            display: none;
        }
    }
    .mobile{
        display: none;
        @media (max-width: 768px) {
            display: block;
            width: 100%;
        }
    }
}