.root {
  --bg-color-hover: rgba(132, 148, 185, 0.1);
  --size: 36px;
  display: flex;
  padding-top: 24px;
}

.position {
  &Left {
    justify-content: flex-start;
  }

  &Right {
    justify-content: flex-end;
  }

  &Center {
    justify-content: center;
  }
}

.item {
  min-width: var(--size);
  height: var(--size);
  transition: background-color var(--transition-duration);
  border-radius: 12px;

  @media (hover) {
    &:hover,
    &:focus-visible {
      background-color: var(--bg-color-hover);
    }
  }
}

.link {
  display: grid;
  place-items: center;
  padding: 4px;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: var(--tone-700);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  border-radius: 12px;

  &Active {
    color: var(--white);
    background-color: var(--comp-500);

    @media (hover) {
      &:hover,
      &:focus-visible {
        opacity: 0.7;
      }
    }
  }
}

.ellipsis {
  display: flex;

  &Link {
    height: 24px;
    width: 24px;
    text-decoration: none;
    text-align: center;
    color: var(--info);
  }
}

.prevItem,
.nextItem {
  height: var(--size);
  width: var(--size);
  border-radius: 12px;
  transition: background-color var(--transition-duration);

  @media (hover) {
    &:hover,
    &:focus-visible {
      background-color: var(--bg-color-hover);
    }
  }
}

.prevLink,
.nextLink {
  height: var(--size);
  width: var(--size);
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  fill: var(--tone-700);
}

.disabled {
  opacity: 0.5;
  user-select: none;
  pointer-events: none;

  [aria-disabled='true'] {
    outline: none;
  }
}
