.root {
    .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 991px) {
            flex-direction: column;
            gap: 32px;
            align-items: flex-start;
        }
        &__wrapper {
            padding: 32px 0;
        }
    }

    .email {
        color: #315D8F;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Kanit;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        /* 28.8px */
    }

    .social {
        display: flex;
        align-items: center;
        gap: 12px;

        &__link {
            transition: all 0.2s;
            &:hover {
                transform: scale(1.1);
            }
        }
    }

    .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: rgba(17, 22, 39, 0.76);
        text-align: center;
        font-family: Kanit;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 142.857% */
        @media (max-width: 768px) {
            flex-direction: column;
            gap: 24px;
        }

        &__wrapper {
            padding: 18px 0;
            border-top: 1px solid rgba(118, 130, 173, 0.24);
        }
    }

    .owlab {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .menu {
        display: flex;
        align-items: center;
        gap: 29px;
        @media (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
        }

        &__link {
            color: rgba(17, 22, 39, 0.86);
            font-family: Kanit;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            /* 150% */
            &:hover {
                opacity: 70%;
            }
        }
    }
}