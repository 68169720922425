@import 'src/assets/styles/mixins';

.root {
  padding: 32px;
}

.backToProfile {
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--tone-700);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;

  svg {
    transition: transform 0.2s ease-in-out;
  }

  &:hover {
    svg {
      transform: scale(1.1);
    }
  }
}

.tableTitle {
  color: var(--tone-900);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px
}
