.root {
    padding: 64px 0;
    background-color: #fff;

    .wrapper {
        max-width: 1000px;
        margin: 0 auto;
    }

    .title {
        color: #111627;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Kanit;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 118%;
        /* 42.48px */
        text-align: center;
        margin-bottom: 40px;

        @media (max-width: 1100px) {
            font-size: 24px;
            margin-bottom: 24px;
        }
    }

    :global(.slick-dots li button:before) {
        font-size: 12px !important;
        color: rgba(26, 177, 207, 1);

        @media (max-width: 991px) {
            font-size: 8px;
        }
    }

    :global(.slick-prev) {
        background-image: url('./images/prev.svg');
        background-repeat: no-repeat;
        background-size: cover;
        transition-duration: 0.1s !important;
        z-index: 1;
        width: 60px;
        height: 60px;
        left: -60px;

        @media (max-width: 1200px) {
            left: -30px;
        }

        @media (max-width: 1100px) {
            left: 0;
            width: 40px;
            height: 40px;
        }

        &:hover {
            background-image: url('./images/prev_hov.svg');
        }
    }

    :global(.slick-prev::before) {
        display: none;
    }

    :global(.slick-next) {
        background-image: url('./images/next.svg');
        background-repeat: no-repeat;
        background-size: cover;
        transition-duration: 0.1s !important;
        z-index: 1;
        width: 60px;
        height: 60px;
        right: -60px;

        @media (max-width: 1200px) {
            right: -30px;
        }

        @media (max-width: 1100px) {
            right: 0;
            width: 40px;
            height: 40px;
        }

        &:hover {
            background-image: url('./images/next_hov.svg');
        }
    }

    :global(.slick-next::before) {
        display: none;
    }

    :global(.slick-disabled) {
        display: none !important;
    }

    :global(.slick-track) {
        display: flex !important;
        align-items: center;
    }

    :global(.slick-slide) {
        height: inherit !important;
        padding: 15px;
    }

    .item {
        display: flex;
        justify-content: center;

        @media (max-width: 1100px) {
            margin-bottom: 10px;
        }

        a {
            img {
                max-width: 250px;
            }
        }
    }
}