@import 'src/assets/styles/mixins';

.root {
  --color-disabled: var(--tone-500);
  padding: 16px 22px;
  min-width: 216px;
  height: 56px;
  font-family: inherit;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: normal;
  text-transform: none;
  border-radius: 24px;

  &:global(.MuiButton) {
    &-contained {
      color: var(--white);
      background-color: var(--primary);
      box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.4) inset, 0 -4px 0 0 rgba(0, 0, 0, 0.32) inset;
      @include colorHover($bg-color: var(--primary-dark));

      &:active {
        box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.4) inset, 0 -2px 0 0 rgba(0, 0, 0, 0.32) inset;
        transition: none;
      }

      &Primary {}

      &Secondary {
        color: var(--primary);
        background-color: var(--tone-200);
        box-shadow: none;
        @include colorHover($bg-color: var(--brand-100));

        &:active {
          background-color: var(--brand-200);
          box-shadow: none;
        }

        &:disabled,
        &[aria-disabled='true'] {
          --color-disabled: var(--tone-400);
        }
      }

      &Info {
        background-color: var(--info);
        @include colorHover($bg-color: var(--info-dark));
      }

      &Success {
        background-color: var(--success);
        @include colorHover($bg-color: var(--success-dark));
      }

      &Error {
        background-color: var(--error);
        @include colorHover($bg-color: var(--error-dark));
      }

      &Warning {
        background-color: var(--warning);
        @include colorHover($bg-color: var(--warning-dark));
      }

      &:disabled,
      &[aria-disabled='true'] {
        color: var(--white);
        background-color: var(--color-disabled);
      }
    }

    &-outlined {
      color: var(--primary);
      border-color: currentColor;
      @include colorHover($bg-color: var(--primary-light));

      &Primary {}

      &Secondary {
        color: var(--secondary);
        @include colorHover($bg-color: var(--secondary-light));
      }

      &Info {
        color: var(--info);
        @include colorHover($bg-color: var(--info-light));
      }

      &Success {
        color: var(--success);
        @include colorHover($bg-color: var(--success-light));
      }

      &Error {
        color: var(--error);
        @include colorHover($bg-color: var(--error-light));
      }

      &Warning {
        color: var(--warning);
        @include colorHover($bg-color: var(--warning-light));
      }

      &:disabled,
      &[aria-disabled='true'] {
        color: var(--color-disabled);
        border-color: var(--color-disabled);
      }
    }

    &-text {
      color: var(--primary);
      background-color: var(--primary-light);
      @include colorHover($bg-color: var(--primary-light));

      &Primary {}

      &Secondary {
        color: var(--secondary);
        @include colorHover($bg-color: var(--secondary-light));
      }

      &Info {
        color: var(--info);
        @include colorHover($bg-color: var(--info-light));
      }

      &Success {
        color: var(--success);
        @include colorHover($bg-color: var(--success-light));
      }

      &Error {
        color: var(--error);
        @include colorHover($bg-color: var(--error-light));
      }

      &Warning {
        color: var(--warning);
        @include colorHover($bg-color: var(--warning-light));
      }

      &:disabled,
      &[aria-disabled='true'] {
        color: var(--color-disabled);
        box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.4) inset, 0 -2px 0 0 rgba(0, 0, 0, 0.32) inset;
      }
    }

    &-transparent {
      color: var(--primary);
      @include colorHover($bg-color: transparent, $color: rgba(0, 138, 165, 1));

      &Primary {}

      &Secondary {
        color: var(--secondary);
        @include colorHover($bg-color: var(--secondary-light));
      }

      &Info {
        color: var(--info);
        @include colorHover($bg-color: var(--info-light));
      }

      &Success {
        color: var(--success);
        @include colorHover($bg-color: var(--success-light));
      }

      &Error {
        color: var(--error);
        @include colorHover($bg-color: var(--error-light));
      }

      &Warning {
        color: var(--warning);
        @include colorHover($bg-color: var(--warning-light));
      }

      &:disabled,
      &[aria-disabled='true'] {
        color: var(--color-disabled);
        box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.4) inset, 0 -2px 0 0 rgba(0, 0, 0, 0.32) inset;
      }
    }

    &-sizeSmall {
      padding: 10px 7.5px;
      min-width: 90px;
      height: 40px;
      font-size: 14px;
      line-height: 20px;
      border-radius: 16px;
    }

    &-sizeLarge {
      min-width: 224px;
      height: 64px;
      font-size: 18px;
      line-height: 20px;
    }
  }

  :global {
    .MuiButton {

      &-startIcon,
      &-endIcon {
        svg {
          width: 24px;
          height: 24px;
          object-fit: contain;
        }
      }
    }
  }
}