.loader {
  position: relative;
}

.wheel {
  position: absolute;
  width: 98%;
  height: 98%;
  top: 0;
  left: 0;
  path {
    animation: spin 5s infinite linear;
    transform-style: preserve-3d;
    transform-origin: center;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rock {
  width: 20%;
  position: absolute;
  bottom: 0;
  right: 0;
}