:root {
  --font: 'Kanit', sans-serif;
  --subfont: var(--font);

  --tone-100: #ffffff;
  --tone-200: rgba(118, 130, 173, 0.08);
  --tone-300: rgba(118, 130, 173, 0.12);
  --tone-400: rgba(118, 130, 173, 0.23);
  --tone-500: rgba(17, 22, 39, 0.32);
  --tone-600: rgba(17, 22, 39, 0.65);
  --tone-700: rgba(17, 22, 39, 0.76);
  --tone-800: rgba(17, 22, 39, 0.86);
  --tone-900: rgba(17, 22, 39, 0.96);

  --absolute-100: var(--tone-100);
  --absolute-200: #f4f5f8;
  --absolute-300: #eff0f5;
  --absolute-400: #dfe2ec;
  --absolute-500: #a0a2a9;
  --absolute-600: #646873;
  --absolute-700: #4a4e5b;
  --absolute-800: #323745;
  --absolute-900: #161b2b;
  --absolute-1000: #000000;

  --bg-01: var(--absolute-100);
  --bg-02: #f6f8fa;
  --bg-overlay: rgba(17, 24, 39, 0.64);

  --brand-500: #1ab1cf;
  --brand-600: #179eba;
  --brand-100: rgba(26, 177, 207, 0.12);
  --brand-200: rgba(26, 177, 207, 0.2);

  --comp-500: #315d8f;
  --comp-400: #5a7da5;

  --accent-green: #5ecc2a;
  --accent-green-100: rgba(91, 178, 50, 0.16);
  --accent-green-500: #5bb232;
  --accent-red-500: #f64a4a;
  --accent-red-600: #f52f2f;
  --accent-yellow: #f2b842;
  --accent-purple: #7d46ff;
  --accent-blue-500: #058bff;
  --accent-blue-600: #0078e0;
  --accent-blue-100: rgba(5, 139, 255, 0.12);

  --primary: var(--brand-500);
  --primary-light: var(--brand-100);
  --primary-dark: var(--brand-600);

  --secondary: var(--comp-400);
  --secondary-light: var(--brand-200);
  --secondary-dark: var(--comp-500);

  --info: var(--accent-blue-500);
  --info-light: var(--accent-blue-100);
  --info-dark: var(--accent-blue-600);

  --success: var(--accent-green);
  --success-light: #c7f1c9;
  --success-dark: #388e3c;

  --error: var(--accent-red-500);
  --error-light: rgba(246, 74, 74, 0.04);
  --error-dark: var(--accent-red-600);

  --warning: var(--accent-yellow);
  --warning-light: #faebd6;
  --warning-dark: #f57c00;

  --light: var(--absolute-200);
  --dark: var(--absolute-900);

  --white: var(--absolute-100);
  --black: var(--absolute-1000);
  --gray: var(--absolute-500);
  --red: #ff3001;

  --big-container: 100%;
  --container: 1390px;
  --small-container: 890px;
  --transition-duration: 0.3s;
}