@import 'src/assets/styles/mixins';

.root {
  width: min-content;
  height: min-content;
  transition: color var(--transition-duration), background-color var(--transition-duration);

  &:focus-visible {
    background-color: transparent !important;
  }

  &.disabled {
    color: rgba(0, 0, 0, 0.2);
  }
}

.color {
  &Primary {
    color: var(--primary);
    @include colorHover(var(--primary-dark), var(--primary-light));
  }

  &Secondary {
    color: var(--secondary);
    @include colorHover(var(--secondary-dark), var(--secondary-light));
  }

  &Info {
    color: var(--info);
    @include colorHover(var(--info-dark), var(--info-light));
  }

  &Success {
    color: var(--success);
    @include colorHover(var(--success-dark), var(--success-light));
  }

  &Error {
    color: var(--error);
    @include colorHover(var(--error-dark), var(--error-light));
  }

  &Warning {
    color: var(--warning);
    @include colorHover(var(--warning-dark), var(--warning-light));
  }
}

.sizeSmall {
  padding: 4px;

  &.edgeStart {
    margin-left: -4px;
  }

  &.edgeEnd {
    margin-right: -4px;
  }
}

.sizeMedium {
  padding: 8px;

  &.edgeStart {
    margin-left: -8px;
  }

  &.edgeEnd {
    margin-right: -8px;
  }
}

.sizeLarge {
  padding: 12px;

  &.edgeStart {
    margin-left: -12px;
  }

  &.edgeEnd {
    margin-right: -12px;
  }
}
