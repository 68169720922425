.coinsList {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;

  &__coin {
    padding: 8px 12px;
    display: flex;
    align-items: center;
    column-gap: 6px;
    border-radius: 12px;
    background: var(--bg-01);
    box-shadow: 0 16px 48px 0 rgba(0, 0, 0, 0.04);
    color: var(--tone-700);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    font-size: 14px;
    line-height: 1;

    &.inPopover {
      box-shadow: none;
      background: transparent;

      &:hover {
        background: var(--bg-02);
      }
    }

    &.selected {
      background: var(--comp-500);
    }
  }

  &__coinIconWrapper {
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__coinIcon {
    height: 20px;
    max-width: 20px;
    width: auto;
  }

  &__coinName {
    color: var(--tone-900);
    line-height: 1;

    &.selected {
      color: var(--white);
    }
  }

  &__coinCode {
    font-style: normal;
    color: var(--tone-700);

    &.withName {
      color: var(--tone-500);
    }

    &.selected {
      color: var(--white);
    }
  }

  &__otherCoinsBtn {
    padding: 8px 12px;
    display: flex;
    align-items: center;
    column-gap: 6px;
    border-radius: 12px;
    background: var(--bg-01);
    box-shadow: 0 16px 48px 0 rgba(0, 0, 0, 0.04);
    color: var(--comp-400);
    font-weight: 400;
    cursor: pointer;
    text-transform: inherit;
    font-family: inherit;
  }

  &__popover {
    margin-top: 8px;
    padding: 16px 8px;
    border-radius: 12px;
    background: var(--bg-01);
    box-shadow: 0 16px 48px 0 rgba(0, 0, 0, 0.04);
    color: var(--tone-700);
    width: 100%;
    max-width: max-content;
    max-height: 60vh;
  }
}

.bitgemRate {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 12px;

  &.inModal {
    flex-wrap: nowrap;
  }

  &__usdRate,
  &__selectedCoinRate {
    padding: 8px 16px;
    border-radius: 12px;
    box-shadow: 0 16px 48px 0 rgba(0, 0, 0, 0.04);
    display: flex;
    align-items: center;
    column-gap: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    span {
      display: flex;
      align-items: center;
    }
  }

  &__usdRate {
    background: var(--accent-green-100);
    color: var(--accent-green-500);
    font-style: normal;
  }

  &__selectedCoinRate {
    background: var(--bg-01);
    color: var(--tone-700, rgba(17, 22, 39, 0.76));
    font-feature-settings: 'clig' off, 'liga' off;
  }
}

.vchtBalance {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__balance {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;

    p {
      color: var(--tone-600);
      display: flex;
      align-items: center;
      text-align: center;
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
    }
  }

  &__amount {
    margin: 4px 0 8px;
    display: flex;
    align-items: center;
    column-gap: 4px;

    strong {
      color: var(--accent-purple);
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }
  }

  &__btn {
    height: 44px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
}
