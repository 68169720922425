.root {
    min-height: 100vh;
    background-image: url('./images/join_game.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
    @media (max-width: 768px) {
        min-height: 500px;
        background-image: none;
    }

    .link {
        width: 100%;
        min-height: 100vh;
        display: block;
        @media (max-width: 768px) {
            min-height: 500px;
        }
    }

    .mobile__img{
        display: none;
        @media (max-width: 768px) {
            display: block;
            width: 100%;
        }
    }

    .join {
        min-width: 186px;
        transition: all 0.05s;
        position: absolute;
        color: #16B6D6;
        text-align: center;
        font-family: Kanit;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
        /* 160% */
        display: inline-flex;
        padding: 12px 30px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 100px;
        background: #FFF;
    }
}