@import 'src/assets/styles/mixins';

.root {
  display: block;

  :global(.MuiModal-backdrop) {
    background-color: var(--bg-overlay);
  }
}

.paper {
  width: 100%;
  max-width: 424px;
  color: inherit;
  background-color: var(--white);
  border-radius: 24px;
  box-shadow: none;

  &.withoutPaddings {
    max-width: 600px;
    width: 600px;
    overflow: visible !important;
    height: min-content;
    max-height: calc(100% - 230px);
    position: relative;
  }
}

.content {
  position: relative;
  overflow: auto;
  padding: 32px;
  max-height: 850px;
  @include scrollbar(10px, var(--primary), var(--light), 2px, 5px);

  .paperFullScreen & {
    max-height: 100%;
    height: 100%;
  }

  :global {
    h1 {
      font-size: 24px;
      line-height: 28px;
    }

    p {
      font-size: 15px;
      line-height: 20px;
      font-weight: 300;
    }
  }

  &.withoutPaddings {
    padding: 0;
    overflow: visible !important;
    max-height: 100%;
    display: flex;
    min-height: 400px;
  }
}

.button {
  position: absolute;
  top: 16px;
  display: flex;
  width: 40px;
  height: 40px;
  color: var(--tone-900);
  border-radius: 12px;
  transition: background-color var(--transition-duration);

  @media (hover) {

    &:hover,
    &:focus-visible {
      background-color: var(--tone-200);
    }
  }

  &Left {
    left: 16px;
  }

  &Right {
    right: 16px;
  }
}

.buttonIcon {
  margin: auto;
  fill: currentColor;
}