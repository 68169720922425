.root {
  display: inline-flex;
  max-width: 200px;

  &:global(.MuiFormControl-fullWidth) {
    max-width: 100%;
  }

  .label {
    font-family: inherit;
    font-size: 14px;
    line-height: 1;
    letter-spacing: normal;
    transform: translate(14px, 17px) scale(1);

    & + .formControl {
      margin-top: 0;
    }

    &Focused {
      color: var(--comp-400);
    }

    &Shrink {
      transform: translate(0, -6px) scale(0.75);

      &.labelOutlined {
        position: relative;
        //transform: translate(13px, -6px) scale(0.75);
        transform: none;
        margin-bottom: 4px;
        margin-left: 8px;
        text-align: left;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        color: var(--tone-600);
      }

      &.labelFilled {
        transform: translate(13px, 2px) scale(0.75);
      }
    }

    &Error {
      color: var(--error);
    }

    &Disabled {
      color: var(--tone-500);
    }
  }

  .input {
    padding-left: 16px;
    padding-right: 16px;
    font-family: inherit;
    font-size: 14px;
    line-height: inherit;
    font-weight: 400;
    letter-spacing: normal;
    color: var(--tone-900);
    border-radius: 20px;

    &.disabled {
      color: var(--gray);
    }

    &.multiline {
      padding: 0 16px;
    }
  }

  // Outline input
  .outlined {
    .notchedOutline {
      border-color: var(--tone-500);
      transition: border-color var(--transition-duration);

      legend span {
        padding: 0;
        width: 0;
      }
    }

    &:hover {
      .notchedOutline {
        border-color: var(--comp-400);
      }
    }

    &.focused {
      .notchedOutline {
        border-width: 2px;
        border-color: var(--comp-400);
      }
    }

    &.disabled {
      .notchedOutline {
        border-color: var(--tone-500);
      }
    }

    &.error {
      background-color: var(--error-light);

      .notchedOutline {
        border-color: var(--error);
      }
    }
  }

  // Filled input
  .filled {
    background-color: var(--light);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  // Underline for Standard input & Filled input
  .underline {
    &:hover:not(.disabled):before {
      border-bottom: 1px solid var(--comp-400);
    }

    &:before {
      border-color: var(--gray);
    }

    &:after {
      border-bottom: 1px solid var(--comp-400);
    }
  }

  .inputElement {
    box-sizing: border-box;
    //padding: 15px 40px 15px 16px;
    padding: 15px 0;
    height: 54px;

    &.inputSizeSmall {
      padding-top: 10px;
      padding-bottom: 10px;
      height: 38px;
    }
  }

  .endAdornment {
    //position: absolute;
    //right: 0;
  }

  .helperText {
    overflow: hidden;
    margin: 0;
    padding: 4px 8px;
    width: 100%;
    font-family: inherit;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: normal;
    white-space: nowrap;
    text-overflow: ellipsis;

    &Error {
      color: var(--error);
    }
  }

  .errorIcon {
    //margin-right: 16px;
    color: var(--error);
    //cursor: pointer;
  }
}
