.counter {
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.24);
  backdrop-filter: blur(6.587738037109375px);
  padding: 16px;

  color: #FFF;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;

  min-width: 70px;
  text-align: center;
}