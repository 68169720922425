.root {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 32px 0;
    z-index: 100;
    transition: all 0.2s;

    @media (max-width: 768px) {
        padding: 18px 0;
    }

    &.scrolled {
        padding: 10px 0;
        background-color: #fff;
        box-shadow: 1px 3px 21px 0px rgba(0, 0, 0, 0.75);
    }

    .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .logo {
        width: 166px;

        @media (max-width: 768px) {
            width: 100px;
        }
    }

    .button {
        width: 144px;
        min-width: unset;

        @media (max-width: 768px) {
            width: 64px;
            height: 40px;
            font-size: 13px;
        }
    }

    .link {
        width: 144px;
        padding: 18px 20px;
        border-radius: 24px;
        background: rgba(255, 255, 255, 0.20);
        backdrop-filter: blur(2px);
        color: #FFF;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        margin-right: 8px;
        margin-left: auto;

        &:hover {
            background: rgba(255, 255, 255, 0.35);
        }

        @media (max-width: 768px) {
            width: 94px;
            height: 40px;
            font-size: 13px;
            padding: 10px 5px;
        }
    }
}