@import 'src/assets/styles/mixins';

.arena {
  display: flex;
  background-position: center center;
  background-size: cover;
  animation-duration: 500s;
  animation-name: arenaAnimationBackground;
  animation-play-state: running;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  position: relative;
  overflow: hidden !important;

  &Overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(270deg, rgba(0, 0, 0, 0.12) 0%, #000 100%);
    mix-blend-mode: hard-light;
    z-index: 3;

    &2 {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.5;
      background: linear-gradient(90deg, #56347D 0%, rgba(86, 52, 125, 0.00) 100%);
      z-index: 5;
    }

    &Total {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: var(--bg-overlay);
      z-index: 7;
    }
  }

  &Container {
    display: inherit;
    flex: 1;
    padding: 100px 32px;
    z-index: 9;
  }
}

.switcher {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.24);
  backdrop-filter: blur(8px);
  padding: 4px;
  display: flex;
  gap: 4px;

  &Btn {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: unset;
    max-height: unset;
    min-width: unset;
    min-height: unset;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    padding: 0;
    border: 1px solid rgba(255, 255, 255, 0.12);

    &>span {
      color: #fff;
    }

    &.active {
      border: none;
      background-color: #fff;

      &:hover {
        background-color: #fff;
      }
    }
  }
}

.room {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 100px;
  width: 100%;
  padding-left: calc(100px - 32px);

  &Selected {
    max-width: 440px;

    h1 {
      color: var(--absolute-100);
      font-size: 40px;
      font-weight: 600;
      line-height: 48px;
      margin-bottom: 24px;
    }

    p {
      color: var(--absolute-400);
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      margin-bottom: 32px;
      white-space: pre-line;
    }
  }

  &Selector {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &Item {
    width: 200px;
    margin: auto;
    overflow: hidden;
    position: relative;
    background-position: center center;
    background-size: cover;
    cursor: pointer;
    border-radius: 24px;

    &::before {
      content: "";
      padding-top: 69%;
      float: left;
    }

    .roomStake {
      border-radius: 16px;
      display: flex;
      align-items: center;
      position: absolute;
      color: var(--absolute-100);
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      bottom: 12px;
      right: 12px;
      padding: 2px 6px 2px 2px;
      gap: 2px;
      z-index: 200;
      transition: all 150ms;

      svg {
        width: 23px;
        height: 23px;
      }
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      z-index: 100;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &.selected {
      border: 2px solid var(--absolute-100);

      &::after {
        background: linear-gradient(0deg, rgba(17, 22, 39, 0.24) 0%, rgba(17, 22, 39, 0.24) 100%);
        box-shadow: 0px 16px 48px 0px rgba(0, 0, 0, 0.04);
      }

      .roomStake {
        top: 50%;
        left: 50%;
        right: auto;
        bottom: auto;
        transform: translate(-50%, -50%);
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;

        svg {
          width: 44px;
          height: 44px;
        }
      }
    }

    &:not(.selected) {
      &::after {
        background: linear-gradient(0deg, rgba(17, 22, 39, 0.50) 0%, rgba(17, 22, 39, 0.50) 100%);
      }

      .roomStake {
        background: rgba(255, 255, 255, 0.08);
        backdrop-filter: blur(2px);
      }
    }
  }

  &Actions {
    display: flex;
    gap: 16px;
  }

  &Join {
    width: auto;
    min-width: unset;

    .separator {
      width: 1px;
      height: calc(100% + 16px + 16px - 5px);
      background: #179EBA;
      margin-left: 32px;
      margin-top: -2px;
    }

    .stake {
      padding: 0 0 0 16px;
      display: flex;
      gap: 2px;

      svg {
        width: 23px;
        height: 23px;
      }
    }

    &:hover {
      .separator {
        background: var(--primary);
      }
    }
  }

  &Avatars {
    display: flex;
    border-radius: 32px;
    background: rgba(255, 255, 255, 0.24);
    backdrop-filter: blur(6px);
    height: 52px;
    padding: 4px;

    div {
      height: 100%;
      position: relative;
      border-radius: 50%;
      overflow: hidden;

      img {
        height: inherit;
        object-fit: cover;
      }

      &:not(:first-child) {
        margin-left: -16px;
      }

      &:last-child.notLast {
        &::after {
          content: '+' attr(data-more-count);
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: var(--tone-700);
          color: #FFF;
          font-size: 18px;
          font-weight: 500;
          line-height: 28px;
        }
      }
    }
  }

  &Statistic {
    position: absolute;
    bottom: 32px;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 24px;

    &Item {
      display: inherit;
      gap: 8px;
      align-items: center;
      border-radius: 32px;
      background: rgba(17, 22, 39, 0.32);
      backdrop-filter: blur(8px);
      padding: 3px 20px 3px 3px;

      svg {
        width: 47px;
      }

      div {
        display: inherit;
        flex-direction: column;
        white-space: nowrap;

        p {
          color: #DFE2EC;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
        }

        span {
          color: var(--absolute-100);
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
        }
      }

      &:last-child {
        svg {
          margin-top: -3px;
        }
      }
    }
  }
}

.agreementModal {
  display: flex;
  flex-direction: column;

  &>h1 {
    text-align: center;
  }

  &Stake {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 50px;
      height: 50px;
      margin-left: -10px;
    }

    p {
      color: var(--tone-900);
      font-size: 28px;
      font-weight: 600;
      line-height: 28px;
    }

    span {
      color: var(--tone-900);
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      margin-left: 4px;
    }
  }

  &>span {
    color: var(--tone-700);
    text-align: center;
    font-size: 15px;
    font-weight: 300;
    line-height: 20px;
    margin-top: 12px;
  }

  &Text {
    height: 135px;
    border-radius: 16px;
    border: 1px solid var(--tone-500, rgba(17, 22, 39, 0.32));
    padding: 0 10px 0 16px;
    margin-top: 24px;

    &Inner {
      padding: 12px 0;
      height: 100%;
      display: flex;
      flex-direction: column;

      h2 {
        color: var(--tone-900);
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 6px;
      }

      p,
      li {
        color: var(--tone-700);
        font-size: 12px;
        font-weight: 300;
        line-height: 20px;
      }

      li {
        padding-left: 6px;
      }
    }
  }

  &Checkbox {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-top: 16px;

    p {
      color: var(--tone-700);
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  &Buttons {
    margin-top: 24px;
    display: flex;
    gap: 12px;

    button {
      min-width: unset;
      width: calc(50% - 6px);
    }
  }
}

.LowBalanceModal {
  position: relative;
  width: 100%;

  .LeaveLeft,
  .LeaveRight,
  .Roof,
  .TitleTable {
    position: absolute;
  }

  .Roof {
    top: -70px;
    left: -10px;
    width: calc(100% + 20px);
    max-width: unset;
    z-index: 100;
  }

  .TitleTable {
    top: -80px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
  }

  .LeaveLeft,
  .LeaveRight {
    z-index: -1;
  }

  .LeaveLeft {
    left: -60px;
    top: 100px;
  }

  .LeaveRight {
    right: -50px;
    top: 30px;
  }

  &Wrapper {
    //overflow: auto;
    height: 100%;
    border-radius: 24px;
  }

  &Inner {
    display: flex;
    flex-direction: column;
    padding: 60px 24px 24px;
    gap: 24px;
    align-items: center;

    .balance {
      display: flex;
      flex-direction: column;
      align-items: center;

      &>svg {
        margin-bottom: -56px;
      }

      &>div {
        height: 56px;
        padding: 12px 16px;
        display: flex;
        justify-content: space-between;
        border-radius: 24px;
        background: var(--bg-02, #F6F8FA);
        backdrop-filter: blur(6px);
        gap: 20px;

        &>div {
          display: flex;
          gap: 8px;
          align-items: center;

          &:first-child {
            svg {
              //margin-bottom: -4px;
            }
          }

          span {
            color: var(--tone-700);
            font-size: 13px;
            font-weight: 400;
            line-height: 14px;
          }

          p {
            color: var(--tone-900);
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
          }

          &.separator {
            width: 1px;
            height: 100%;
            background: rgba(118, 130, 173, 0.12);
          }
        }
      }
    }

    .desc {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      max-width: 350px;

      p {
        color: var(--tone-700);
        text-align: center;
        font-size: 15px;
        font-weight: 300;
        line-height: 20px;
      }
    }
  }
}

.waitingRoom {
  &Container {
    padding: 48px;
    z-index: 10;
    width: 100%;
    position: relative;
  }

  &Heading {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    z-index: 100;
    position: relative;

    .users {
      display: flex;
      flex-direction: column;
      gap: 8px;

      p {
        color: var(--absolute-100);
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
      }

      span {
        color: rgba(255, 255, 255, 0.40);
      }

      .winners {
        display: flex;
        align-items: center;
        gap: 2px;
        padding: 4px 24px 4px 4px;
        border-radius: 32px;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.00) 100%);
        transition: all 250ms;

        &>* {
          transition: opacity 250ms;
          opacity: 1;
        }

        &.secret {
          &>* {
            opacity: 0;
          }
        }

        &.looser {
          gap: 8px;
        }

        &.me {
          border-radius: 32px;
          background: linear-gradient(270deg, #FE9900 0%, #FFD83B 100%);
          backdrop-filter: blur(6.587738037109375px);
        }

        &>img {
          width: 36px;
          height: 36px;
          object-fit: cover;
          border-radius: 50%;
        }

        &>svg {
          width: 30px;
          height: 30px;
          margin-bottom: -3px;
        }

        .userName {
          display: flex;
          flex-direction: column;
          justify-content: center;

          &>span {
            color: var(--absolute-100);
            font-size: 14px;
            font-weight: 300;
            line-height: 12px;
          }

          &>p {
            font-size: 15px;
            font-weight: 500;
            line-height: 18px;
          }
        }
      }
    }

    .actions {
      display: flex;
      gap: 12px;

      &>button {
        height: 48px;
        min-width: unset;
        border-radius: 16px;
        box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.40) inset, 0px -2px 0px 0px rgba(0, 0, 0, 0.32) inset;

        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }

  &Participants {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 150px;

    &.roomResults {
      justify-content: center;
    }

    &Item {
      display: flex;
      position: absolute;
      margin: auto;
      overflow: hidden;
      left: 50%;
      top: 50%;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.40);
      animation-name: arenaRoomPulseAnimation;
      animation-play-state: running;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;

      &:before {
        content: "";
        padding-top: 100%;
        float: left;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    &Result {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      &>img:not(.rays) {
        position: absolute;
        z-index: 10;
        top: 50%;
        left: 50%;
        opacity: 0;
        border-radius: 50%;
        border: 4px solid white;
        transform: translate(0, 0) rotate(0deg) scale(0.6);
        transition: all 500ms;

        &.animate {
          opacity: 1;
          transform: translate(-250px, -150px) rotate(-30deg) scale(1);
        }
      }

      &>img.rays {
        position: absolute;
        height: 100vh;
        width: 100vh;
        max-width: unset;
        animation-duration: 50s;
        animation-name: arenaRaysAnimation;
        animation-play-state: running;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        transition: opacity 300ms;

        &.hide {
          opacity: 0;
        }
      }

      &>div:not(.winAmount) {
        margin-top: -50px;
        transition: opacity 300ms;

        &.hide {
          opacity: 0;
        }
      }

      &>div.winAmount {
        color: var(--brand-500);
        font-size: 56px;
        font-weight: 700;
        background-color: #fff;
        padding: 20px;
        border-radius: 16px;
        position: absolute;
        z-index: 10;
        top: 50%;
        left: 50%;
        opacity: 0;
        border: 4px solid white;
        transform: translate(0, 0) rotate(0deg) scale(0.6);
        transition: all 500ms;

        &.animate {
          opacity: 1;
          transform: translate(150px, 50px) rotate(10deg) scale(1);
        }

        &>div {
          position: absolute;
          bottom: -15px;
          right: -15px;
          background-color: #fff;
          color: var(--brand-500);
          padding: 0 6px;
          border-radius: 4px;
          font-size: 16px;
          font-weight: 700;
          /* background: linear-gradient(180deg, #1AB1CF 0%, rgba(26, 177, 207, 0.00) 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent; */
        }
      }

      h1 {
        color: var(--absolute-100);
        text-align: center;
        font-size: 32px;
        font-weight: 600;
        line-height: 32px;
        position: absolute;
        z-index: 10;
        top: 50%;
        left: 50%;
        opacity: 0;
        transition: all 250ms;
        transform: translate(-50%, 0) scale(0.6);

        &.visible {
          opacity: 1;
          transform: translate(-50%, -300px) scale(1);
        }
      }
    }
  }

  &UserTooltip {
    &:not(img) {
      border-radius: 24px;
      background: var(--tone-100, #FFF);
      box-shadow: 0px 16px 48px 0px rgba(0, 0, 0, 0.04);
      border-radius: 30px;
      padding: 8px 16px;
      color: var(--tone-800, rgba(17, 22, 39, 0.86));
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
    }
  }

  &ActionButtons {
    display: flex;
    gap: 16px;
    justify-content: center;
    position: absolute;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);

    &>button {
      &:first-child {
        background-color: #fff;

        &:hover {
          background-color: #e3fbff;
        }
      }
    }
  }

  &WinMessage {
    position: absolute;
    bottom: 160px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 24px;
    background: rgba(17, 22, 39, 0.32);
    backdrop-filter: blur(12px);
    padding: 12px 24px;
    color: var(--absolute-100, #FFF);
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    display: none;

    &.visible {
      display: block;
    }
  }
}

.leaveModal {
  text-align: center;

  h1,
  p {
    padding: 0 30px;
  }

  &>p {
    margin-top: 16px;
    margin-bottom: 32px;
  }

  .buttons {
    display: flex;
    gap: 16px;

    &>button {
      min-width: unset;
      width: -webkit-fill-available;

      &:nth-child(1):not(.normal) {
        background-color: var(--tone-200);
      }
    }
  }

  &>* {
    text-align: center;
  }
}

.results {
  flex: 1;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  .countDown {
    display: flex;
    z-index: 10;
    position: relative;
    top: -40px;
    left: -40px;

    h1 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(calc(-50% + 40px), -150%);
      width: max-content;
      text-align: center;
      color: var(--absolute-100, #FFF);
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;
    }

    svg {
      position: absolute;
      opacity: 0;
      transform: translate(0, 0) rotate(0deg) scale(1);
      transition: all 1s;

      &.visible {
        opacity: 1;
      }

      &.animate {
        visibility: visible;
        transform: translate(-50px, 50px) rotate(-30deg) scale(0.5);
        opacity: 0;
      }
    }
  }
}

@keyframes arenaAnimationBackground {
  0% {
    background-position: center center;
  }

  33% {
    background-position: right center;
  }

  66% {
    background-position: left center;
  }

  100% {
    background-position: center center;
  }
}

@keyframes arenaRoomPulseAnimation {
  0% {
    scale: 1;
  }

  33% {
    scale: 1.009;
  }

  66% {
    scale: 0.991;
  }

  100% {
    scale: 1;
  }
}

@keyframes arenaRaysAnimation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}