@import 'src/assets/styles/mixins';

$packHeight: 260px;
$packWidth: 240px;

.title {
  margin-bottom: 16px;
}

.header {
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  &.withSlider {
    padding: 16px 36px 0;
    flex-direction: column-reverse;
    gap: 8px;
  }
}

.bitgemPacks {
  display: grid;
  grid-template-columns: repeat(auto-fill, $packWidth);
  grid-gap: 24px;

  &Section {
    &.withSlider {
      background: var(--tone-200);
    }
  }

  &.withSlider {
    display: flex;
    padding-bottom: 30px;

    :global(.slick-track) {
      display: flex;
      gap: 12px;
    }

    :global(.slick-slide) {
      height: calc(260px + 7px) !important;
      width: calc(240px + 35px) !important;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      overflow: hidden;
      border-radius: 24px;
      //box-shadow: 0 16px 48px 0 rgba(0, 0, 0, 0.04);

      &:first-child {
        margin-left: 36px;
      }

      &:last-child {
        margin-right: 36px;
      }
    }

    :global(.slick-slider) {
      width: 600px;
      overflow: hidden;
      position: relative;
    }

    :global(.slick-list) {
      width: 100%;
    }

  }
}

.bitgemPack {
  height: $packHeight !important;
  width: $packWidth !important;
  border-radius: 24px;
  background: var(--bg-01);
  box-shadow: 0 16px 48px 0 rgba(0, 0, 0, 0.04);
  display: flex !important;
  flex-direction: column;
  align-items: center;
  padding-bottom: 16px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  &__sliderIcon {
    margin: 62px auto 24px;
  }

  &__manualText {
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    max-width: 163px;
  }

  &__buyButton {
    margin-top: auto;
  }

  &__amount {
    color: var(--tone-900);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;

    strong {
      font-size: 28px;
    }

    &.withCustomBg {
      margin-top: 140px;
      color: var(--tone-100);
    }
  }

  &__bigtgemsIcon {
    margin-top: 19px;
  }

  &__labelIcon {
    position: absolute;
    top: -34px;
    right: -42px;
  }

  &__extraCHT {
    position: absolute;
    top: 84px;
    left: 58px;
  }
}

.arrow {
  display: flex !important;
  position: absolute;
  width: 56px;
  height: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 100;


  &Prev {
    left: 0;
    top: 0;
    background: linear-gradient(90deg, rgb(245, 245, 249) 0%, rgba(255, 255, 255, 0.00) 100%);
  }

  &Next {
    right: 0;
    top: 0;
    background: linear-gradient(-90deg, rgb(245, 245, 249) 0%, rgba(255, 255, 255, 0.00) 100%);

    svg {
      transform: rotate(180deg);
    }
  }

  &.hidden {
    display: none !important;
  }
}