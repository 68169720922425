@import 'src/assets/styles/mixins';

.transactions {
  &__row {
    color: var(--tone-900);
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 12px;

    &.date {
      font-weight: 300;
    }

    .icon {
      min-width: 40px;
      width: 40px;
      height: 40px;
    }

    &.toData,
    &.fromData {
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      gap: 0;
    }

    .toSource,
    .fromSource {
      color: var(--tone-900);
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 20px;
    }

    .toAmount,
    .fromAmount,
    .toAddress,
    .fromAddress {
      color: var(--tone-700);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }

    .completed {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: var(--brand-600);
    }

    .error {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: var(--accent-red-500);
    }

    .pending,
    .processing {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: var(--accent-yellow);
    }
  }

  &__noGames {
    min-height: 300px;

    svg {
      fill: none !important;
    }

    p {
      margin-bottom: 24px;
      color: var(--tone-700);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  &__filters {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__filter {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background 0.2s ease-in-out;
    position: relative;

    &:hover {
      background: var(--tone-200);
    }

    &__active {
      background: var(--tone-200);
    }

    &__dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: var(--brand-600);
      position: absolute;
      top: 4px;
      right: 4px;
    }
  }

  &__dateFilter {
    border-radius: 16px;
    border: 1px solid var(--tone-500);
    min-width: 160px;
    height: 40px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    padding: 10px 12px;
    color: var(--tone-900);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
    transition: border 0.2s ease-in-out;

    &:hover {
      border: 1.5px solid var(--comp-400);
    }

    p {
      padding-left: 8px;
      margin-left: auto;
      color: var(--accent-red-500);
    }
  }

  &__dateFilterPopover {
    margin-top: 8px;
    padding: 8px 16px;
    border-radius: 16px;
    background: var(--bg-01);
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
  }

  &__filterPopover {
    margin-top: 8px;
    padding: 8px 16px;
    border-radius: 16px;
    background: var(--bg-01);
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
    width: 310px;
    @include scrollbar(5px, var(--primary), var(--light), 5px, 2px);

    &__options {
      display: flex;
      flex-direction: column;
      gap: 4px;
      max-height: 200px;
    }

    &__option {
      height: 40px;
      padding: 8px;
      display: flex;
      align-items: center;
      column-gap: 8px;
      color: var(--tone-900);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      border-radius: 12px;
      cursor: pointer;

      svg {
        width: 24px;
        min-width: 24px;
      }

      img {
        width: 24px;
        min-width: 24px;
      }

      &.selected,
      &:hover {
        background: var(--bg-02, #f6f8fa);
      }
    }
  }
}
