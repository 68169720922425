.timeSwitchers {
  display: flex;
  align-items: center;
  column-gap: 12px;

  &__item {
    color: var(--tone-500);
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;

    &:hover {
      color: var(--comp-500, #315d8f);
      transition: color 0.2s ease-in-out;
    }

    &__active {
      color: var(--comp-500, #315d8f);
      font-weight: 500;
    }
  }
}

.myProfile,
.companyInfo {
  &__title {
    margin-bottom: 24px;
    color: var(--tone-900);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }

  &__subtitle {
    color: var(--tone-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    max-width: 400px;
  }

  &__separator {
    margin: 24px 0;
    width: 100%;
    height: 1px;
    background-color: var(--tone-400);
  }

  &__submit {
    min-width: 120px;
    width: 120px;
    height: 48px;
    border-radius: 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
}

.companyInfo {
  &__form {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  &__submit {
    min-width: 148px;
    width: 148px;
  }

  &__warningCapsule {
    margin-bottom: 16px;
    background-color: var(--accent-blue-100);
    border-radius: 24px;
    padding: 12px 16px;
    color: var(--tone-700);
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;

    a {
      color: var(--accent-blue-600);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      text-decoration-line: underline;
    }
  }

  &__contacts {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    border-radius: 24px;
    border: 1px solid var(--tone-400);
    padding: 16px;
  }

  &__knowledgeLink {
    margin-bottom: 42px;
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--comp-500, #315D8F);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  &__contactsSection {
    flex: 1;
    display: flex;
    align-items: center;
    column-gap: 12px;
  }

  &__contactsLabel {
    color: var(--tone-600);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  &__contactsValue {
    color: var(--tone-900);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  &__contactsIcons {
    display: flex;
    align-items: center;
    column-gap: 4px;

    svg {
      width: 40px;
      height: 40px;
    }
  }

  &__emailIcon {
    border-radius: 50%;
    background: var(--bg-02);
    width: 40px;
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}