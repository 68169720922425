.root {
  z-index: 50;
  background: var(--white);

  .container {
    padding: 0 22px 0 32px;
    display: flex;
    align-items: stretch;
    height: 68px;
  }

  .myAccountButton {
    margin: auto 0;
    padding: 8px 12px;
    height: 40px;
    min-width: 103px;
    border-radius: 16px;
    border: 1.5px solid var(--comp-400);
    color: var(--comp-500);
  }

  .logout {
    margin-right: -4px;
    padding: 4px;
    width: 32px;
    height: 32px;

    path {
      transition: stroke var(--transition-duration);
    }

    &:hover path {
      stroke: var(--error);
    }
  }

  .header {
    justify-content: space-between;

    &Buttons,
    &Info {
      display: flex;
      align-items: center;
    }

    &Buttons {
      gap: 16px;

      .linkToLanding {
        padding: 12px;
        color: var(--tone-700);
        font-size: 15px;
        font-weight: 500;
        line-height: 24px;
      }
    }

    &Info {
      gap: 20px;

      .balance {
        display: flex;
        gap: 4px;
        align-items: center;

        &>div {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        p {
          font-family: var(--font);
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
        }

        &_cht p {
          color: var(--accent-purple);
        }

        &_cht svg {
          margin-right: 2px;
          width: 30px;
          height: 30px;
        }

        &_bit p {
          color: var(--accent-blue-500);
        }

        span {
          color: var(--tone-600);
          font-family: var(--font);
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
        }
      }

      .volumeWrapper {
        width: 24px;
        height: 24px;
        position: relative;

        &>svg {
          cursor: pointer;
        }

        .volumeSliderWrapper {
          display: none;
          position: absolute;
          width: 200px;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);

          &Inner {
            width: 100%;
            margin-top: 12px;
            display: flex;
            align-items: center;
            gap: 8px;
            border-radius: 16px 0 16px 16px;
            background: var(--bg-01, #FFF);
            box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.08), 0px -1px 8px 0px rgba(0, 0, 0, 0.04);
            padding: 16px;
          }
        }

        &:hover {
          .volumeSliderWrapper {
            display: flex;
          }
        }
      }

      .profileDropdown {
        position: relative;

        &__button {
          display: flex;
          gap: 8px;
          align-items: center;

          img {
            width: 40px;
            height: 40px;
          }

          svg {
            transition: transform 150ms;
          }

          &.open {
            svg {
              transform: rotate(180deg);
            }
          }
        }

        &__content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          position: absolute;
          top: calc(100% + 12px);
          right: 0;
          width: 284px;
          border-radius: 16px;
          background: var(--bg-01, #FFF);
          box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.08), 0px -1px 8px 0px rgba(0, 0, 0, 0.04);
          padding: 16px;

          &>a {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 16px;
            padding: 4px;
            color: var(--tone-900);
          }

          &>div {
            width: 100%;
            justify-content: space-between;
          }
        }
      }
    }
  }
}

.user {
  margin-left: auto;
  margin-right: 10px;
  text-align: right;

  .userMail {
    font-family: var(--subfont);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #13141a;
  }
}