.root {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
  flex: 1;
  overflow: hidden;

  .container {
    display: inherit;
    flex-direction: column;
    width: calc(100% - 256px);
  }
}