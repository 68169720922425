.coinsSelect {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;

  &__coin {
    padding: 8px 12px;
    display: flex;
    align-items: center;
    column-gap: 6px;
    border-radius: 12px;
    color: var(--tone-700);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    font-size: 14px;
    line-height: 1;
    box-shadow: none;
    background: transparent;

    &:hover {
      background: var(--bg-02);
    }

    &.selected {
      background: var(--comp-500);
    }
  }

  &__coinIconWrapper {
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__coinIcon, svg {
    height: 20px;
    max-width: 20px;
    width: auto;
  }

  &__coinName {
    color: var(--tone-900);
    line-height: 1;

    &.selected {
      color: var(--white);
    }
  }

  &__coinCode {
    font-style: normal;
    color: var(--tone-500);

    &.selected {
      color: var(--white);
    }
  }

  &__otherCoinsBtn {
    padding: 8px 12px;
    display: flex;
    align-items: center;
    column-gap: 6px;
    border-radius: 12px;
    background: var(--bg-01);
    box-shadow: 0 16px 48px 0 rgba(0, 0, 0, 0.04);
    color: var(--comp-400);
    font-weight: 400;
    cursor: pointer;
    text-transform: inherit;
    font-family: inherit;
  }

  &__coinSelect {
    padding: 8px 12px;
    display: flex;
    align-items: center;
    column-gap: 6px;
    border-radius: 16px;
    border: 1px solid var(--tone-400, rgba(118, 130, 173, 0.23));
    color: var(--tone-800, rgba(17, 22, 39, 0.86));
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    svg {
      margin-left: -2px;
    }
  }

  &__popover {
    margin-top: 8px;
    padding: 8px;
    border-radius: 16px;
    background: var(--bg-01);
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
    color: var(--tone-700);
    width: 100%;
    max-width: max-content;
    max-height: 60vh;
  }
}

.paymentDiscretion {
  margin: 16px 0 24px;
}

.sellBitgemsDiscretion {
  margin-bottom: 24px;
}

.paymentDiscretion, .sellBitgemsDiscretion {
  border-radius: 24px;
  background: var(--brand-100);
  padding: 12px 16px;
  color: var(--tone-700);
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
  width: 100%;

  strong {
    color: var(--brand-500);
    font-weight: 400;
  }

  a {
    color: var(--accent-blue-500);
    text-decoration-line: underline;
  }
}

.extraCHT {
  margin-top: 16px;
  padding: 16px;
  width: 100%;
  border-radius: 24px;
  background: linear-gradient(149deg, rgba(136, 54, 252, 0.12) 13.56%, rgba(177, 121, 255, 0.12) 92.39%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;

  &__leftBlock {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  &__rightBlock {
    text-align: end;
  }

  &__label {
    display: flex;
    align-items: center;
    column-gap: 4px;
    color: var(--tone-900);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    position: relative;
  }

  &__moreInfo {
    height: 16px;
    width: auto;

    path {
      stroke: var(--tone-900);
    }
  }

  &__tooltip {
    position: absolute;
    top: 6px;
    left: 2px;
    display: none;

    &.isOpen {
      display: block;
    }
  }
}
