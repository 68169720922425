@import 'src/assets/styles/mixins';

.root {
  padding: 40px 32px;
  // 100vh - header height
  min-height: calc(100vh - 68px);
  display: flex;
  flex-flow: column;
  gap: 40px;
}
