@import 'src/assets/styles/mixins';

.root {
  padding-top: 24px;
  padding-bottom: 24px;

  @include mq(lg-down) {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.bitgem {
  padding-left: 0 !important;
  padding-right: 0 !important;

  &__header,
  &__body {
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  }

  &__header {
    &__block {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    &__icon {
      min-width: 40px;
      width: 40px;
      height: 40px;
      background-color: var(--bg-02);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__coinName {
      margin-bottom: 2px;
      color: var(--tone-900);
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }

    &__coinDescription {
      color: var(--tone-600);
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
    }

    &__profit {
      text-align: end;
    }

    &__profitLabel {
      margin-bottom: 2px;
      color: var(--tone-500);
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
    }

    &__profitValue {
      color: var(--tone-900);
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      display: flex;
      align-items: center;
      justify-content: end;
    }
  }

  &__body {
    &__actions {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &__btn {
      min-width: 88px;
      height: 45px;
      border-radius: 16px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;

      svg {
        margin-left: 8px;
        width: 20px;
        height: 20px;
      }
    }
  }
}

.tokens {
  padding: 0 !important;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  &__balance {
    margin-bottom: 8px;
    color: var(--tone-900);
    font-size: 28px;
    font-weight: 600;
    line-height: 28px;
  }

  &__balanceInUSDT {
    color: var(--tone-600);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    align-items: center;
  }

  &__exchange {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: -20px;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    background-color: var(--absolute-200);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;

    &:hover {
      transform: translateY(-50%) scale(1.1);
    }
  }

  &__convertBtn {
    position: absolute;
    right: 32px;
    bottom: 32px;
  }
}

.cht {
  &__header {
    border-top-left-radius: 20px;
    padding: 16px 52px 16px 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--tone-300);
    border-right: 1px solid var(--tone-300);
  }

  &__body {
    border-bottom-left-radius: 20px;
    padding: 32px 52px 32px 32px;
  }

  &__block {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__icon {
    width: 40px;
    height: 40px;
    background-color: var(--accent-yellow);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__coinName {
    margin-bottom: 2px;
    color: var(--tone-900);
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }

  &__coinCode {
    color: var(--tone-600);
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }

  &__walletLabel {
    color: var(--tone-600);
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.26px;
  }

  &__walletAddress {
    color: var(--tone-900);
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.26px;
  }
}

.vcht {
  &__header {
    border-top-right-radius: 20px;
    padding: 16px 32px 16px 52px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__icon {
    width: 40px;
    height: 40px;
    background-color: var(--accent-purple);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__moreInfo {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--tone-600);
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.26px;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 0.7;
    }
  }

  &__body {
    position: relative;
    border-bottom-right-radius: 20px;
    padding: 32px 32px 32px 52px;
    border-top: 1px solid var(--tone-300);
    border-left: 1px solid var(--tone-300);
  }
}

.transactionsTable {
  &__title {
    margin-bottom: 4px;
    color: var(--tone-900);
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  }

  &__subtitle {
    color: var(--tone-600);
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
  }
}