.root {
  margin: auto;
  padding: 32px;
  width: 100%;
  max-width: 420px;
  text-align: center;
  background: var(--bg-01);
  box-shadow: 0 16px 48px rgba(0, 0, 0, 0.04);
  border-radius: 24px;
}

.title {
  margin-bottom: 16px;
}

.desc {
  font-size: 15px;
}

.footer {
  margin-bottom: -32px;
  margin-left: -32px;
  margin-right: -32px;
  padding: 20px 42px;
  text-align: center;
  font-weight: 300;
  color: var(--absolute-300);
  background: {
    image: url('../../assets/images/auth-footer-bg.png'), linear-gradient(180deg, #3e6899 0%, #3e6299 100%);
    position: center;
    size: cover;
    repeat: no-repeat;
  }
  border-radius: 0 0 24px 24px;

  a {
    color: var(--absolute-300);

    &:hover,
    &:focus-visible {
      text-decoration: underline;
      text-underline-offset: 2px;
    }
  }
}

.network {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px;
  width: 100%;
  border-radius: 20px;
  border: 1px solid var(--tone-400);
}

.networkText {
  width: 100%;

  h3 {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  span {
    font-size: 13px;
    font-weight: 300;
    line-height: 20px;
  }
}
